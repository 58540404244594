@import '../../defaults';

.dashtext-title{
    width: 100%;

    .text{
        font-size: 1.3em;
        font-weight: 800;
        color:var(--title);
    }

}