@import '../../defaults';

.dash-card-iconcard{

    width: 100%;
    height: 100%;
    min-width: 200px;

    grid-row: auto;

    .box{
        display: grid;
        grid-template-columns: 80px 1fr;
        row-gap: 1px;
        column-gap: 15px;
        width: 100%;
        height: 100%;
        align-items: center;
        box-sizing: border-box;
        padding: 20px 15px;
        font-size: 1em;

        @include generic-box;

        &.success{

            .title{
                color: var(--success);
                font-weight: 600;
            }
        }
    
        &.warning{
    
            .title{
                color: var(--warning);
                font-weight: 600;
            }
        }
    
        &.danger{
    
            .title{
                color: var(--danger);
                font-weight: 600;
            }
        }

        .icon{
            grid-row: 1 / span 2;
            text-align: center;
            box-sizing: border-box;
            padding: 5px;

            i{
                width: 70%;
                height: 70%;
                display: flex !important;
                align-items: center;
                justify-content: center;
            }

            svg {
                fill: #FFF;
                width: 70%;
                height: 70%;
                display: block;
                margin: 0 auto;
            }

            .circle{
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: var(--title);
                box-sizing: border-box;

            }
        }

        .title{
            @include title-box;
            color: var(--title);
            font-size: 2em;
        }

        .content{
            font-weight: 200;
            font-size: 0.95em;
        }

    }
}