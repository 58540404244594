@font-face {
    font-family: 'A-Signature';
    src: url('../../assets/fonts/signaturefonts/A-Signature.otf'), url('../../assets/fonts/signaturefonts/A-Signature.woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Authentic Signature';
    src: url('../../assets/fonts/signaturefonts/Authentic Signature.otf'), url('../../assets/fonts/signaturefonts/Authentic Signature.woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'BeautyDiary';
    src: url('../../assets/fonts/signaturefonts/BeautyDiary.otf'), url('../../assets/fonts/signaturefonts/BeautyDiary.woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Autography-DOLnW';
    src: url('../../assets/fonts/signaturefonts/Autography-DOLnW.otf'), url('../../assets/fonts/signaturefonts/Autography-DOLnW.woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Bellandha';
    src: url('../../assets/fonts/signaturefonts/Bellandha.ttf'), url('../../assets/fonts/signaturefonts/Bellandha.woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'DarlingtonDemo-z8xjG';
    src: url('../../assets/fonts/signaturefonts/DarlingtonDemo-z8xjG.ttf'), url('../../assets/fonts/signaturefonts/DarlingtonDemo-z8xjG.woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'JustSignature';
    src: url('../../assets/fonts/signaturefonts/JustSignature.ttf'), url('../../assets/fonts/signaturefonts/JustSignature.woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'PWsignaturefont';
    src: url('../../assets/fonts/signaturefonts/PWsignaturefont.ttf'), url('../../assets/fonts/signaturefonts/PWsignaturefont.woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Signatrue';
    src: url('../../assets/fonts/signaturefonts/Signatrue.ttf'), url('../../assets/fonts/signaturefonts/Signatrue.woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Signatures';
    src: url('../../assets/fonts/signaturefonts/Signatures.otf'), url('../../assets/fonts/signaturefonts/Signatures.woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Signatures_2';
    src: url('../../assets/fonts/signaturefonts/Signatures_2.otf'), url('../../assets/fonts/signaturefonts/Signatures_2.woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SignatureVP-PersonalUse';
    src: url('../../assets/fonts/signaturefonts/SignatureVP-PersonalUse.ttf'), url('../../assets/fonts/signaturefonts/SignatureVP-PersonalUse.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SignaturexDemoRegular';
    src: url('../../assets/fonts/signaturefonts/SignaturexDemoRegular.ttf'), url('../../assets/fonts/signaturefonts/SignaturexDemoRegular.woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Thesignature';
    src: url('../../assets/fonts/signaturefonts/Thesignature.otf'), url('../../assets/fonts/signaturefonts/Thesignature.woff');
    font-weight: normal;
    font-style: normal;
}

.page-signature{
    width: 100%;
    height: auto;
    display: flex;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
    justify-content: center;
}

.signature-area{

    border:1px solid #ccc;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .area-button{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 94%;

        button{
            background-color: #eee;
            color: #666;
            border-radius: 5px;
            width: auto;
            border: 0;
            box-sizing: border-box;
            padding: 5px 10px;
            cursor: pointer;
            display: inline-block;
        } 

        button + button{
            margin-left: 5px;
        }
    }

    .area{
        width: 100%;
        height: auto;
        min-height: 150px;
        margin: 0 auto;
        position: relative;
        box-sizing: border-box;

        &:before{
            position: absolute;
            bottom: 50px;
            width: 90%;
            left: 50%;
            transform: translateX(-50%);
            border-bottom: 3px solid #000;
            content: '';
            display: block;
            opacity: 0.5;
        }

    }

    .dialog-remote{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        display: none;
        align-items: center;
        justify-content: center;
        z-index: 1000;

        &.active{
            display: flex;
        }

        canvas{
            position: relative;
            z-index: 100;
        }

        .close-button{
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0px;
            left: 0px;
            background-color: rgba(255, 255, 255, 0.8);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &::before{
                content: 'X';
                display: flex;
                pointer-events: none;
                border-radius: 50%;
                width: 32px;
                height: 32px;
                background-color: var(--danger);
                position: absolute;
                top: 20px;
                right: 20px;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                color: #FFF;

            }
        }
    }
}


.create-area{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 465px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    // background-color: rgba(255, 255, 255, 0.9);
    pointer-events: none;
    transition: all ease 400ms;
    opacity: 0;
    

    &.active{
        pointer-events: auto;
        transition: all ease 600ms;
        opacity: 1;
        
        > .main-frame-create-signature{
            margin-top: 0px;
            transition: all ease 400ms;
        }
    }

    .close-button{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0px;
        right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;

        // &::before{
        //     content: 'X';
        //     display: flex;
        //     pointer-events: none;
        //     border-radius: 50%;
        //     width: 32px;
        //     height: 32px;
        //     background-color: var(--danger);
        //     align-items: center;
        //     justify-content: center;
        //     font-weight: bold;
        //     color: #FFF;

        // }
    }

   > .main-frame-create-signature{
        width: 100%;    
        max-width: 300px;
        height: auto;
        max-height: 300px;
        position: absolute;
        z-index: 100;
        background-color: #FFF;
        border-radius: 10px;
        box-shadow: 0px 2px 5px #666;
        transition: all ease 600ms;

        margin-top: -5px;

        &::before{
            content:'';
            display: block;
            background-color: #FFF;
            width: 0px;
            height: 0px;
            position: absolute;
            top: 0;
            left: 50%;
            border-width: 20px 20px 0 0;
            border-color: #FFF #FFF transparent transparent;
            border-style: solid;
            transform: translate(-50%, -50%) rotate(45deg);
            box-shadow: -2px -2px 5px #CCC;
            z-index: 1;
        }
    }


    .list-fonts{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 98%;    
        max-width: 300px;
        height: auto;
        max-height: 290px;
        overflow: auto;
        position: relative;
        z-index: 10;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 5px;

        > div {
            box-sizing: border-box;
            padding: 5px 10px;
            width: 100%;
            display: block;
        }

        > div + div{
            border-top: 1px solid #CCC;
        }

        input[type="radio"]{
            opacity: 0;

            &:checked{
                + label{
                    &::before{
                        content: '';
                        display: block;
                        background-color: #666;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translate(-50%, -50%);
                        width: 5px;
                        height: 5px;
                    }
                }
            }
        }

        label{
            width: 100%;
            display: flex;
            line-height: 26px;
            vertical-align: center;
            cursor: pointer;
            border-bottom: 1px solid transparent;
            box-sizing: border-box;
            padding-left: 10px;
            position: relative;
        }
    }

}