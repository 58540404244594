
@mixin generic-box {
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(150, 150, 150, .5);
}

@mixin title-box {
    font-weight: 800;
    font-size: 1em;
}