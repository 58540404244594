@import '../../_defaults';

.dash-card-barprogress{
    .box{
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 1px;
        column-gap: 15px;
        width: 100%;
        height: 100%;
        align-items: center;
        box-sizing: border-box;
        padding: 20px 15px;
        font-size: 1em;

        @include generic-box;

        .title{
            @include title-box;
            font-weight: 800;
            color:var(--title);
        }

        .content{
            width: 100%;

            .leggend{
                display: flex;
                justify-content: center;
                margin: 10px auto;
                width: 100%;
                flex-wrap: wrap;

                > div{
                    display: grid;
                    grid-template-columns: 20px 1fr;
                    column-gap: 5px;
                    align-items: center;
                    margin: 0 10px;
                    font-size: 0.8em;

                    &::before{
                        width: 20px;
                        height: 10px;
                        display: block;
                        background-color: var(--color);
                        content: '';
                    }

                    &::after{
                        width: 100%;
                        content: attr(value);
                        display: block;

                    }
                }
            }

            .bar{
                display: flex;
                margin: 10px auto;

                > div{
                    background-color: var(--color);
                    display: inline-flex;
                    width: var(--val);
                    height: 10px;
                    box-sizing: border-box;
                    text-align: center;
                    justify-content: center;
                    position: relative;
                    transition: all ease 600ms;

                    &:hover{
                        transition: all ease 600ms;
                        opacity: 0.8;

                        &::before{
                            display: inline-block;
                            content: attr(value);
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            transform: translate(-50%, 100%);
                            box-sizing: border-box;
                            padding: 5px;
                            background-color: #eee;
                            font-size: 0.8em;
                        }
                    }
                }
            }
        }
    }
}