@import '../../defaults';

.dash-card-chart{

    width: 100%;
    height: 100%;

    grid-row: auto;

    .box{
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 1px;
        column-gap: 15px;
        width: 100%;
        height: 100%;
        align-items: center;
        box-sizing: border-box;
        padding: 20px 15px;
        font-size: 1em;

        @include generic-box;

        .title{
            @include title-box;
            font-weight: 800;
            color:var(--title);
        }

        .content{
            
            canvas{
                width: 100% !important;
                display: block;
                position: relative;
            }
        }

    }
}