::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  border-radius: 5px;
  padding-right: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dcdcdc;
  border-radius: 5px;
  max-width: 10px;
}

.modal-content {
  border-radius: 20px;
}

.__react_component_tooltip {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
}

a {
  text-decoration: none !important;
  display: contents !important;
  width: 100% !important;
}

a:hover {
  text-decoration: none !important;
}
.RichTextEditor__root___2QXK- {
  width: 100%;
}

.row {
  align-items: center;
}

body{
  overflow: hidden;
}
