@import './../../defaults';

.dashui-divisor{

    width: 100%;
    height: 100%;

    .bar{
        box-sizing: border-box;

        &.horizontal{
            width: 100%;
            height: auto;

            .label{
                color: var(--title);
                display: flex;
                align-items: center;
                box-sizing: border-box;

                &::before{
                    content:'';
                    display: inline-block;
                    height: 1px;
                    width: 100%;
                    border-bottom: var(--bordersize) var(--bordertype) var(--bordercolor);
                    box-sizing: border-box;
                }

                &::after{
                    content:'';
                    display: inline-block;
                    height: 1px;
                    width: 100%;
                    border-bottom: var(--bordersize) var(--bordertype) var(--bordercolor);
                    box-sizing: border-box;
                }

                .text{
                    margin: 0 10px;
                    min-height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 800;
                }

                .text:empty{
                    margin: 0;
                    min-height: unset;
                }
            }
        }


        &.vertical{
            width: auto;
            height: 100%;

            .label{
                color: var(--title);
                display: flex;
                flex-direction: column;
                align-items: center;
                box-sizing: border-box;

                &::before{
                    content:'';
                    display: inline-block;
                    height: 100%;
                    min-height: 100px;
                    width: 1px;
                    border-right: var(--bordersize) var(--bordertype) var(--bordercolor);
                    box-sizing: border-box;
                }

                &::after{
                    content:'';
                    display: block;
                    height: 100%;
                    min-height: 100px;
                    width: 1px;
                    border-right: var(--bordersize) var(--bordertype) var(--bordercolor);
                    box-sizing: border-box;
                }

                .text{
                    margin: 0 10px;
                    min-height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 800;
                }

                .text:empty{
                    margin: 0;
                    min-height: unset;
                }
            }
        }

    }
}
