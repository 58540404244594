@import '../../defaults';

.dash-card-listcard{

    width: 100%;
    height: 100%;
    
    .box{
        @include generic-box;
        padding: 5px 10px;
        min-height: 200px;
        height: 100%;

        .title{
            @include title-box;
            color:var(--title);
        }

        .subtitle{
            font-size: 0.8em;
            font-weight: 200;
            margin: 2.5px 10px;
            color:var(--title);
        }

        .content{
            font-size: 0.8em;
            margin: 10px 5px;
            overflow-y: auto;
            max-height: calc(100% - 80px);

            > div {
                box-sizing: border-box;
                padding: 5px 10px;

                &:nth-child(even){
                    background-color: rgb(245, 245, 245);
                }
            }
        }
    }
}