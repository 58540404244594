@import './defaults';

.dynamic-dash{
    grid-column: 1 / span 2;

    .lastupdate{
        text-align: right;
        font-size: 0.8em;
        margin: 5px 0;
    }

    .ui-grid{
        display: grid;
        row-gap: 7.5px;
        column-gap: 7.5px;
        grid-auto-columns: 1fr;
        grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
        grid-auto-flow: row;
        grid-column-end: 3;

        > div{
            box-sizing: border-box;
            padding: 5px;

            &.merge-1{
                grid-column: auto / span 2;
            }

            &.merge-2{
                grid-column: auto / span 3;
            }

            &.merge-row-1{
                grid-row: auto / span 2;
            }

            &.merge-row-2{
                grid-row: auto / span 3;
            }

            &.merge-row-3{
                grid-row: auto / span 4;
            }
        } 

        &:empty{
            position: relative;
            min-height: 100px;
            @include generic-box;

            &::before{
                content:'Dashboard sem informações';
                position: absolute;
                width: 100%;
                height: 100%;
                display:flex;
                align-items: center;
                justify-content: center;
                color: #DDD;
            }
        }

        .loading{
            position: relative;
            width: 100%;
            grid-column: 1 / span 3;
            min-height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;

            &::before{
                content:'Carregando...';
                color: #eee;
                display: inline-block;
                font-size: 1.5em;
                margin: 0 auto;
            }
        }
    }

    .test{
        @include generic-box;
    }
}