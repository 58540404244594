.editorClassName{
    border: 1px solid #ccc;
    box-sizing: border-box;
    padding: 2px 5px;
    height: auto;
    min-height: 250px;
    max-height: 50vh;
}

.wysiwyg-mention{
    pointer-events: none;
}

.rdw-suggestion-dropdown{
    font-size: 12px;
}

.toolbarClassName{
    box-sizing: border-box;
    padding: 5px;

    .rdw-option-wrapper{
        box-sizing: border-box;
        padding: 5px;
        min-height: 27.5px;
        min-width: 27.5px;
    }

    .rdw-colorpicker-option{
        padding: 0;
        min-height: unset;
        min-width: unset;
    }

    .rdw-dropdown-wrapper{
        min-width: 50px;
    }
}

.rdw-dropdown-wrapper{
    position: relative;

    a {
        display: block !important;
    }
}

.rdw-dropdown-selectedtext{
    width: 100%;
}

.preview-html{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    z-index: 10000;

    .box{
        width: 96vw;
        height: 90vh;
        max-width: 1000px;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 0 5px #333;
        background-color: #fff;

        header{
            width: 100%;
            height: 50px;
            display: grid;
            grid-template-columns: 1fr 40px;
            column-gap: 10px;
            align-items: center;
            border-bottom: 1px solid #eee;

            .title{
                font-weight: bold;
                font-size: 1.5em;
                color: var(--cyan);
            }

            i{
                width: 40px;
                height: 40px;
                display: flex !important;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
        section{
            height: calc(100% - 60px);
            box-sizing: border-box;
            padding: 20px 10px;
            border: 1px solid #DDD;
            margin: 5px auto;
            overflow: auto;
        }
    }
}