@import '../../defaults';

.dashtext-label{
    width: 100%;

    .text{
        font-size: 1em;
        font-weight: 200;
        color:var(--title);
    }

}